import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import Logo from '@hiredigital/ui/Logo';

import Link from '../../Link';
import { DefaultLinks } from './Common';
import Styles from '../Header.module.scss';

const MobileMenu = dynamic(() => import('./MobileMenu'), {
  ssr: false,
});

const Default = ({ onMenuToggle, analyticsData, buttonLabel }) => {
  return (
    <Fragment>
      <Link href='/' className={Styles.logoLink} title={`Hire Digital`}>
        <Logo className={Styles.logo} site={'3'} />
      </Link>
      <MobileMenu onMenuToggle={onMenuToggle}>
        <DefaultLinks>
          <Link
            className={Styles.primaryButton}
            title=''
            href={{
              pathname: '/schedule-call',
              query: { ...analyticsData },
            }}>
            {buttonLabel || 'Work With Talent'}
          </Link>
        </DefaultLinks>
      </MobileMenu>
    </Fragment>
  );
};

export default Default;
