import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import Logo from '@hiredigital/ui/Logo';

import Link from '../../Link';
import { TalentLinks } from './Common';
// import MobileMenu from './MobileMenu';

import Styles from '../Header.module.scss';

const MobileMenu = dynamic(() => import('./MobileMenu'), {
  ssr: false,
});

const Default = ({ analyticsData }) => {
  return (
    <Fragment>
      <Link href='/' className={Styles.logoLink} title={`Hire Digital`}>
        <Logo className={Styles.logo} site={'3'} />
        <h2 className={Styles.logoSuffix}>{`Talent`}</h2>
      </Link>
      <MobileMenu>
        <TalentLinks>
          <Link
            className={Styles.primaryButton}
            title=''
            href={{
              pathname: '/talent/register',
              query: { ...analyticsData },
            }}>
            {`Apply As a Talent`}
          </Link>
        </TalentLinks>
      </MobileMenu>
    </Fragment>
  );
};

export default Default;
